import { useCallback, useEffect, useState } from "react";
import html2canvas from 'html2canvas'
import ReactDOMServer from 'react-dom/server';
import * as XLSX from 'xlsx';
import { kebabCase } from "lodash";
import { PDFDocument } from 'pdf-lib';
import { Buffer } from 'buffer';

const _ = require('lodash');
const translate = require('translate');

const DEFAULT_PNG = {
    fileName: 'chart.jpg',
    type: 'image/jpg',
    html2CanvasOptions: {},
}

async function translateString(str, translateTo) {
    translate.engine = 'google';
    try {
        const translated_string = await translate(str, translateTo);
        return translated_string
    } catch (e) {
        console.log(str)
        console.log(e)
    }
    return ""

}

const saveAs = (uri, filename) => {
    const link = document.createElement('a')
    if (typeof link.download === 'string') {
        link.href = uri
        link.download = filename
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    } else {
        window.open(uri)
    }
}

const exportComponent = async (node, {
    type,
    html2CanvasOptions,
}) => {
    // eslint-disable-next-line react/no-find-dom-node
    const element = node
    var canvas = await html2canvas(element, {
        scrollY: -window.scrollY,
        useCORS: true,
        ...html2CanvasOptions,
    })
    return canvas.toDataURL(type, 1.0)
}

const exportComponentAsPNG = async (node, parameters = {}) => {
    return await exportComponent(node, { ...DEFAULT_PNG, ...parameters })
}

const saveChartAsImage = async (canvas, fileName) => {
    return await exportComponentAsPNG(canvas, { fileName })
}

function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
}

const uploadDocuments = async (files) => {
    const filePromises = [...files].map((file) => {
        // Return a promise per file
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async () => {
                try {
                    const response = {
                        name: file.name,
                        src: reader.result
                    }
                    resolve(response);
                } catch (err) {
                    reject(err);
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    });

    // Wait for all promises to be resolved
    const fileInfos = await Promise.all(filePromises);

    // Profit
    return fileInfos;
};

async function wait1Second(milisecond) {
    return new Promise((accept) => {
        setTimeout(() => {
            accept()
        }, milisecond || 100)
    })
}

function getStringLength(string, fontFamily, fontSize, fontWeight) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
    const textMetrics = context.measureText(string);
    return textMetrics.width;
}

function splitArray(arr, startIndex, maxHeight) {
    let index = startIndex
    const subArrays = [];
    let subArray = [];
    let currentHeight = 0
    for (let i = 0; i < arr.length; i++) {
        currentHeight += arr[i].height + 100
        if (currentHeight < maxHeight) {
            subArray.push(arr[i])
        } else {
            subArrays.push([index, subArray, "Question"]);
            subArray = [arr[i]];
            index++
            currentHeight = arr[i].height + 100
        }
    }
    if (subArray.length > 0) {
        subArrays.push([index, subArray, "Question"]);
    }
    return subArrays;
}

function calculateComponentHeight(Component, props) {
    const html = ReactDOMServer.renderToString(<Component {...props} />);
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    document.body.appendChild(tempDiv);
    const height = tempDiv.offsetHeight;
    document.body.removeChild(tempDiv);
    return height;
}

const listLang = [
    {
        id: "en",
        name: "English"
    },
    {
        id: "vi",
        name: "Tiếng Việt"
    },
    {
        id: "zh",
        name: "简体中文"
    },
    {
        id: "fr",
        name: "Français"
    },
    {
        id: "es",
        name: "Español"
    },
    {
        id: "ru",
        name: "Русский"
    },
    {
        id: "tr",
        name: "Türkçe"
    },
    {
        id: "it",
        name: "Italiano"
    },
    {
        id: "ko",
        name: "한국어"
    },
    {
        id: "id",
        name: "Bahasa Indonesia"
    },
    {
        id: "de",
        name: "Deutsch"
    },
    {
        id: "pl",
        name: "język polski"
    },
    {
        id: "ro",
        name: "Limba română"
    },
    {
        id: "hu",
        name: "Magyar nyelv"
    },
    {
        id: "nl",
        name: "Nederlands"
    },
    {
        id: "pt",
        name: "Português"
    },
    {
        id: "sv",
        name: "Svenska"
    },
    {
        id: "ja",
        name: "日本語"
    },
    {
        id: "ar",
        name: "العربية"
    },
    {
        id: "th",
        name: "ภาษาไทย"
    },
    {
        id: "cs",
        name: "čeština"
    },
    {
        id: "da",
        name: "dansk"
    },
    {
        id: "el",
        name: "Ελληνικά"
    },
    {
        id: "hi",
        name: "हिंदी"
    },
    {
        id: "no",
        name: "norsk"
    },
    {
        id: "sk",
        name: "slovenský jazyk"
    },
    {
        id: "uk",
        name: "украї́нська мо́ва"
    },
    {
        id: "he",
        name: "עִבְרִית"
    },
    {
        id: "fi",
        name: "suomen kieli"
    },
    {
        id: "bg",
        name: "български"
    },
    {
        id: "hr",
        name: "hrvatski"
    },
    {
        id: "lt",
        name: "lietuvių kalba"
    },
    {
        id: "sl",
        name: "slovenski jezik"
    }
]

function ContentWithImage() {
    const [data, setData] = useState([])
    const [dataImage, setDataImage] = useState([])
    const [current, setCurrent] = useState(null)
    const [bigCurrent, setBigCurrent] = useState(null)
    const [dataParseExcel, setDataParseExcel] = useState([])
    const [dataEng, setDataEng] = useState([])
    const [country, setCountry] = useState("")
    const [title, setTitle] = useState("")

    const [textCountry, setTextCountry] = useState("")
    const [loadingAll, setLoadingAll] = useState(false)


    const [text, setText] = useState({
        "HISTORY OF THE WORLD": "HISTORY OF THE WORLD",
        "History on Maps": 'History on Maps',
        "The end": 'The end',
        "ANSWER": 'The end',
    })

    const [dataBase64, setDataBase64] = useState([])
    const [loading, setLoading] = useState(false)
    const [translating, setTranslating] = useState(false)
    const [progress, setProgress] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [page, setPage] = useState(0)
    const [inputPage, setInputPage] = useState("")
    const [loadingFirstTime, setLoadingFirstTime] = useState(false)
    const [listTitle, setListTitle] = useState([])
    const [listTitleEng, setListTitleEng] = useState([])
    const [listQuestion, setListQuestion] = useState([])
    const [language, setLanguage] = useState("en")
    const [textLanguage, setTextLanguage] = useState("en")

    const handleChangeInputPage = (e) => {
        setInputPage(e.target.value)
        setPage(parseInt(e.target.value, 10))
    }

    const onChangeLanguage = (e) => {
        setTextLanguage(e.target.value)
    }

    const onSubmitPage = useCallback((e) => {
        e.preventDefault()
        const item = data.find((x, index) => parseInt(index, 10) === parseInt(inputPage, 10))
        setCurrent(item)
    }, [data, inputPage])


    const prevPage = useCallback(() => {
        const prevPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : parseInt(page, 10)
        setInputPage(`${prevPage}`)
        setPage(prevPage)
        const item = data.find((x, index) => parseInt(index, 10) === parseInt(prevPage, 10))
        setCurrent(item)
    }, [data, page])

    const nextPage = useCallback(() => {
        const nextPage = parseInt(page, 10) + 1 <= maxPage ? parseInt(page, 10) + 1 : parseInt(page, 10)
        setInputPage(`${nextPage}`)
        setPage(nextPage)
        const item = data.find((x, index) => parseInt(index, 10) === parseInt(nextPage, 10))
        setCurrent(item)
    }, [data, page, maxPage])

    const handleUpload = (e) => {
        e.preventDefault();

        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            setDataParseExcel(dataParse)
        };
        reader.readAsBinaryString(f)
    }

    useEffect(() => {
        if (dataParseExcel.length > 0 && dataImage.length > 0) {
            let dataToSave = dataParseExcel.filter((x) => Number.isInteger(x[0]))
            const listTitleToSave = dataToSave.filter((x) => parseInt(x[0], 10) > 2 && dataImage.findIndex((y) => y.name === `${pad(x[0])}.jpg`) === -1).map((x) => x[0])

            dataToSave.unshift([0, "", "start"])
            const startIndex = dataToSave[dataToSave.length - 1][0] + 1
            let dataQues = dataParseExcel.filter((x) => x[0] === "Question").map((item, index) => ({
                index: index + 1,
                maxLengthString: _.maxBy([
                    getStringLength(item[2], 'Inter', '55px', '500'),
                    getStringLength(item[3], 'Inter', '55px', '500'),
                    getStringLength(item[4], 'Inter', '55px', '500'),
                    getStringLength(item[5], 'Inter', '55px', '500')
                ], (d) => d),
                o1: item[2],
                o2: item[3],
                o3: item[4],
                o4: item[5],
                question: item[1],
                answer: item[6],
            }))
            dataQues = dataQues.map((item) => ({
                ...item,
                height: calculateComponentHeight(Question, { item, scale: 1 })
            }))
            setListQuestion(dataQues)

            const heightOfContainer = 2200
            const dataQuesToSave = splitArray(dataQues, startIndex, heightOfContainer)
            dataToSave = [...dataToSave, ...dataQuesToSave]
            dataToSave.push([dataToSave.length, 'ANSWER'])
            listTitleToSave.push(dataToSave.length - 1)
            dataToSave.push([dataToSave.length, 'List Answer'])
            dataToSave.push([dataToSave.length, "", "end"])
            setListTitle(listTitleToSave)
            setListTitleEng(listTitleToSave)
            setDataEng(dataToSave)
            setData(dataToSave)
            setMaxPage(dataToSave.length - 1)
            setPage(dataToSave[0][0])
            setInputPage(dataToSave[0][0])
            setCurrent(dataToSave[0])
            setBigCurrent(dataToSave[0])
        }
    }, [dataImage, dataParseExcel])


    const handleFileChange = async (e) => {
        const rs = await uploadDocuments(e.target.files)
        setDataImage(rs)
    }

    const download = useCallback(async (fileName) => {
        return await saveChartAsImage(document.getElementById("view-cont"), fileName)
    }, [])

    const clickDownload = useCallback(async () => {
        setLoading(true)
        const rs = []
        setBigCurrent(data[0])
        await wait1Second()
        for (let index = 0; index < data.length; index++) {
            rs.push(await download(pad(data[index][0])))
            setProgress(index / data.length)
            if (index < data.length - 1) {
                setBigCurrent(data[index + 1])
                await wait1Second()
            }
        }

        try {
            const pdfData = await createPDF(rs, 1800, 2700);
            const blob = new Blob([pdfData], { type: 'application/pdf' });
            const encodedUri = window.URL.createObjectURL(blob);

            saveAs(encodedUri, `${language}.pdf`)
            saveAs(rs[0], `${language}-start.png`)
            saveAs(rs[rs.length - 1], `${language}-end.png`)



        } catch { }



        setLoading(false)
        setProgress(0)
    }, [data, download, language])



    const scanOne = useCallback(async (lang, dataToScan) => {
        setLoading(true)
        const rs = []
        setBigCurrent(dataToScan[0])
        await wait1Second()
        for (let index = 0; index < dataToScan.length; index++) {
            rs.push(await download(pad(dataToScan[index][0])))
            setProgress(index / dataToScan.length)
            if (index < dataToScan.length - 1) {
                setBigCurrent(dataToScan[index + 1])
                await wait1Second()
            }
        }

        saveAs(rs[0], `${lang}-start.png`)
        saveAs(rs[rs.length - 1], `${lang}-end.png`)

        setLoading(false)
        setProgress(0)
        return {
            language: lang,
            data: rs
        }
    }, [download])


    const downloadOne = async (item, width, height) => {
        const pdfData = await createPDF(item.data, width, height);
        const blob = new Blob([pdfData], { type: 'application/pdf' });
        const encodedUri = window.URL.createObjectURL(blob);
        saveAs(encodedUri, `${item.language}.pdf`)
    }

    const downloadAll = async () => {
        setLoadingAll(true)
        const rs = []
        for (let i = 0; i < listLang.length; i++) {
            const lang = listLang[i];
            setLanguage(lang.id)
            setTextLanguage(lang.id)
            const scan = await translateEbookToScan(lang.id)
            // rs.push(scan)
            downloadOne(scan, 1800, 2700)
        }
        // await Promise.all(rs.map((x) => downloadOne(x, 1800, 2700)))
        setLoadingAll(false)
    }

    const createPDF = async (imageList, width, height) => {
        const pdfDoc = await PDFDocument.create();
        for (let i = 0; i < imageList.length; i++) {
            const page = pdfDoc.addPage([width, height]);
            const y = 0;
            const imageBytes = Buffer.from(imageList[i].replace('data:image/png;base64,', ''), 'base64');
            const image = await pdfDoc.embedPng(imageBytes);
            const x = 0;
            page.drawImage(image, { x, y, width, height });
        }

        const pdfBytes = await pdfDoc.save();
        return pdfBytes;
    }

    const handleChangePage = (selectedPage) => {
        setCurrent(data.find((x) => x[0] === selectedPage))
    }

    const handleChangeCountry = (e) => {
        setTextCountry(e.target.value)
    }

    // const submitCountry = useCallback((e) => {
    //     e.preventDefault()
    //     setCountry(textCountry)
    //     const newText = { ...text }
    //     newText[`History of ${textCountry}`] = `History of ${textCountry}`
    //     newText[`History on Maps: History of ${textCountry}`] = `History on Maps: History of ${textCountry}`
    //     setText(newText)
    // }, [text, textCountry])

    const submitTile = useCallback((e) => {
        e.preventDefault()
        setTitle(textCountry)
        const newText = { ...text }
        newText[textCountry] = textCountry
        newText[`History on Maps: ${textCountry}`] = `History on Maps: ${textCountry}`
        setText(newText)
    }, [text, textCountry])

    const height = window.innerHeight - 80
    const scaleRatio = height / 2700

    const translateQuestion = async (item, lang) => {
        const listToTranslate = [item.o1, item.o2, item.o3, item.o4, item.question]
        const listTranslate = await Promise.all(listToTranslate.map((x) => translateString(x, lang)))
        return {
            ...item,
            o1: listTranslate[0],
            o2: listTranslate[1],
            o3: listTranslate[2],
            o4: listTranslate[3],
            question: listTranslate[4],
            maxLengthString: _.maxBy([
                getStringLength(listTranslate[0], 'Inter', '55px', '500'),
                getStringLength(listTranslate[1], 'Inter', '55px', '500'),
                getStringLength(listTranslate[2], 'Inter', '55px', '500'),
                getStringLength(listTranslate[3], 'Inter', '55px', '500')
            ], (d) => d),
        }
    }

    const translateEbook = useCallback(async (lang) => {
        if (lang !== 'en') {
            setTranslating(true)
            const newText = { ...text }
            const listStatic = ["HISTORY OF THE WORLD", "History on Maps", "The end", `${textCountry}`, `History on Maps: ${textCountry}`]
            const listTransStatic = await Promise.all(listStatic.map((x) => translateString(x, lang)))
            listStatic.forEach((x, index) => {
                newText[x] = listTransStatic[index]
            })

            setText(newText)
            const newData = dataEng.map((x) => [...x])
            const indexQuestion = newData.findIndex((x) => x[2] && x[2] === 'Question')
            let dataToSave = newData.slice(0, indexQuestion)
            const listTransSliceData = await Promise.all(dataToSave.map((x) => x[1] ? translateString(x[1], lang) : ''))
            dataToSave.forEach((x, index) => {
                x[1] = listTransSliceData[index]
            })

            const listTitleToSave = dataToSave.filter((x) => parseInt(x[0], 10) > 2 && dataImage.findIndex((y) => y.name === `${pad(x[0])}.jpg`) === -1).map((x) => x[0])

            let dataQues = await Promise.all(listQuestion.map((x) => translateQuestion(x, lang)))

            dataQues = dataQues.map((item) => ({
                ...item,
                height: calculateComponentHeight(Question, { item, scale: 1 })
            }))

            const heightOfContainer = 2200
            const dataQuesToSave = splitArray(dataQues, indexQuestion, heightOfContainer)
            dataToSave = [...dataToSave, ...dataQuesToSave]
            const textAnswer = await translateString("ANSWER", lang)
            dataToSave.push([dataToSave.length, textAnswer])
            listTitleToSave.push(dataToSave.length - 1)
            dataToSave.push([dataToSave.length, 'List Answer'])
            dataToSave.push([dataToSave.length, "", "end"])
            setListTitle(listTitleToSave)
            setData(dataToSave)
            setCurrent(dataToSave[0])
            setBigCurrent(dataToSave[0])
            setTranslating(false)
            setMaxPage(dataToSave.length - 1)
            setPage(dataToSave[0][0])
            setInputPage(dataToSave[0][0])
            await wait1Second(1000)
        } else {
            const listStatic = ["HISTORY OF THE WORLD", "History on Maps", "The end", `${textCountry}`, `History on Maps: ${textCountry}`]
            const newText = { ...text }
            listStatic.forEach((x) => {
                newText[x] = x
            })
            setListTitle(listTitleEng)
            setText(newText)
            setData(dataEng)
            setCurrent(dataEng[0])
            setBigCurrent(dataEng[0])
            setMaxPage(dataEng.length - 1)
            setPage(dataEng[0][0])
            setInputPage(dataEng[0][0])
            await wait1Second(1000)
        }


    }, [dataEng, listQuestion, text, textCountry, listTitleEng, dataImage])


    const translateEbookToScan = useCallback(async (lang) => {
        if (lang !== 'en') {
            setTranslating(true)
            const newText = { ...text }
            const listStatic = ["HISTORY OF THE WORLD", "History on Maps", "The end", `${textCountry}`, `History on Maps: ${textCountry}`, "ANSWER"]
            const listTransStatic = await Promise.all(listStatic.map((x) => translateString(x, lang)))
            listStatic.forEach((x, index) => {
                newText[x] = listTransStatic[index]
            })

            setText(newText)
            const newData = dataEng.map((x) => [...x])
            const indexQuestion = newData.findIndex((x) => x[2] && x[2] === 'Question')
            let dataToSave = newData.slice(0, indexQuestion)
            const listTransSliceData = await Promise.all(dataToSave.map((x) => x[1] ? translateString(x[1], lang) : ''))
            dataToSave.forEach((x, index) => {
                x[1] = listTransSliceData[index]
            })

            const listTitleToSave = dataToSave.filter((x) => parseInt(x[0], 10) > 2 && dataImage.findIndex((y) => y.name === `${pad(x[0])}.jpg`) === -1).map((x) => x[0])

            let dataQues = await Promise.all(listQuestion.map((x) => translateQuestion(x, lang)))

            dataQues = dataQues.map((item) => ({
                ...item,
                height: calculateComponentHeight(Question, { item, scale: 1 })
            }))

            const heightOfContainer = 2200
            const dataQuesToSave = splitArray(dataQues, indexQuestion, heightOfContainer)
            dataToSave = [...dataToSave, ...dataQuesToSave]
            const textAnswer = newText["ANSWER"]
            const indexAnswer = dataToSave.length
            dataToSave.push([indexAnswer, textAnswer])
            listTitleToSave.push(indexAnswer)
            dataToSave.push([dataToSave.length, 'List Answer'])
            dataToSave.push([dataToSave.length, "", "end"])
            setListTitle(listTitleToSave)
            setData(dataToSave)
            setCurrent(dataToSave[0])
            setBigCurrent(dataToSave[0])
            setTranslating(false)
            setMaxPage(dataToSave.length - 1)
            setPage(dataToSave[0][0])
            setInputPage(dataToSave[0][0])
            await wait1Second()
            const scan = await scanOne(lang, dataToSave)
            return scan
        } else {
            const listStatic = ["HISTORY OF THE WORLD", "History on Maps", "The end", `${textCountry}`, `History on Maps: ${textCountry}`]
            const newText = { ...text }
            listStatic.forEach((x) => {
                newText[x] = x
            })
            setListTitle(listTitleEng)
            setText(newText)
            setData(dataEng)
            setCurrent(dataEng[0])
            setBigCurrent(dataEng[0])
            setMaxPage(dataEng.length - 1)
            setPage(dataEng[0][0])
            setInputPage(dataEng[0][0])
            await wait1Second()
            const scan = await scanOne(lang, dataEng)
            return scan
        }


    }, [scanOne, dataEng, listQuestion, text, textCountry, listTitleEng, dataImage])
    const submitLang = useCallback(() => {
        setLanguage(textLanguage)
        translateEbook(textLanguage)
    }, [textLanguage, translateEbook])

    return (
        <div>
            <div className="d-flex">
                <div style={{ minWidth: 500 }}>
                    <div className="mt-3 ml-3">
                        <form className="d-flex align-items-center" onSubmit={submitTile}>
                            <input className="form-control" name="text" type="text" value={textCountry} onChange={handleChangeCountry} />
                            <button type="submit" style={{ marginLeft: 10, width: 'auto' }} className="btn btn-save">Submit Title</button>
                        </form>
                        <div className="d-flex" style={{ marginTop: 20 }}>
                            <label><strong>Import Images:</strong>&nbsp;&nbsp;</label>
                            <input id="image" name="image" type="file" multiple accept=".jpg, .jpeg, .png" onChange={handleFileChange} />
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <label><strong>Import Excel:</strong>&nbsp;&nbsp;</label>
                            <input type="file" accept=".xlsx" onChange={handleUpload} />
                        </div>
                        <div className="d-flex align-items-center" style={{ marginTop: 20 }}>
                            <select value={textLanguage} className="custom-select" onChange={onChangeLanguage}>
                                {
                                    listLang.map((x) => (
                                        <option key={x.id} value={x.id}>{`${x.name} - ${x.id}`}</option>
                                    ))
                                }
                            </select>
                            <button style={{ marginLeft: 10, width: 'auto' }} className="btn btn-save" onClick={submitLang}>Select language</button>
                        </div>
                    </div>

                    <div className="d-flex align-items-center ml-3" style={{ marginTop: 20, marginBottom: 20 }}>
                        {
                            translating ?
                                <button className="btn btn-save disable" >
                                    Translating to {language}...
                                </button>
                                :
                                !loading ?
                                    <button className="btn btn-save" onClick={clickDownload} >
                                        Download For Current Languague
                                    </button>
                                    :
                                    <button className="btn btn-save disable" >
                                        {/* {`Loading ${Math.floor(progress * 100)}%`} */}
                                        {`Loading ${listLang.find((x) => x.id === language).name} - ${language} version ${Math.floor(progress * 100)}%`}

                                    </button>
                        }
                        {/* <div>
                            {loadingFirstTime && !loading && <PdfGenerator base64DataArray={dataBase64} />}
                        </div> */}
                    </div>
                    <div className="ml-3">
                        {
                            !loadingAll ?
                                <button className="btn btn-save" onClick={downloadAll}>Download All</button>
                                :
                                <button className="btn btn-save disable">Download All</button>
                        }
                    </div>




                </div>
                <div className="col-9 d-flex w-100" style={{
                    height: '100vh',
                    flexGrow: 1
                }}>
                    <div className="w-100" style={{ backgroundColor: "#d9d9d9" }}>
                        <div className="bar">
                            <div className="d-flex align-items-center justify-content-center">
                                <button onClick={prevPage} className="btn MuiButtonBase-root MuiIconButton-root" tabIndex={0} type="button"><span className="MuiIconButton-label"><svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" /></svg></span><span className="MuiTouchRipple-root" /></button>
                                <form
                                    onSubmit={onSubmitPage}
                                    style={{ marginRight: 10, marginLeft: 10 }}>
                                    <input
                                        className="page-handbook"
                                        onChange={handleChangeInputPage}
                                        type="number"
                                        value={inputPage}
                                        placeholder="0"
                                        required
                                    />
                                </form>
                                <div style={{ marginRight: 10 }}>{`of ${maxPage}`}</div>
                                <button onClick={nextPage} className="btn MuiButtonBase-root MuiIconButton-root" tabIndex={0} type="button"><span className="MuiIconButton-label"><svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" /></svg></span><span className="MuiTouchRipple-root" /></button>
                            </div>
                        </div>
                        {current &&
                            <>
                                {
                                    current && current[1] === 'List Answer'
                                        ?
                                        <div className="view-cont" style={{ width: 1800 * scaleRatio, height: 2700 * scaleRatio }}>
                                            <div style={{ textAlign: 'right', padding: `${87 * scaleRatio}px ${84 * scaleRatio}px ${281 * scaleRatio}px ${84 * scaleRatio}px`, fontSize: 40 * scaleRatio }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                            <div className="view-ques-cont" style={{ padding: `0px ${315 * scaleRatio}px 0px ${315 * scaleRatio}px` }}>
                                                <div className="ans-row">
                                                    <div className="ans-col">
                                                        {
                                                            listQuestion.slice(0, Math.ceil(listQuestion.length / 4)).map((item) => (
                                                                <div key={kebabCase(item.question)} className="ans-item" style={{ fontSize: 55 * scaleRatio, marginBottom: 54 * scaleRatio }}>
                                                                    <div className="ques-index" style={{
                                                                        minWidth: 94.16 * scaleRatio,
                                                                        height: 94.16 * scaleRatio,
                                                                        marginRight: 43 * scaleRatio,
                                                                        fontSize: 40 * scaleRatio,
                                                                    }}>
                                                                        {item.index}
                                                                    </div>
                                                                    {item.answer}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="ans-col">
                                                        {
                                                            listQuestion.slice(Math.ceil(listQuestion.length / 4), Math.ceil(listQuestion.length / 4) * 2).map((item) => (
                                                                <div key={kebabCase(item.question)} className="ans-item" style={{ fontSize: 55 * scaleRatio, marginBottom: 54 * scaleRatio }}>
                                                                    <div className="ques-index" style={{
                                                                        minWidth: 94.16 * scaleRatio,
                                                                        height: 94.16 * scaleRatio,
                                                                        marginRight: 43 * scaleRatio,
                                                                        fontSize: 40 * scaleRatio,
                                                                    }}>
                                                                        {item.index}
                                                                    </div>
                                                                    {item.answer}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="ans-col">
                                                        {
                                                            listQuestion.slice(Math.ceil(listQuestion.length / 4) * 2, Math.ceil(listQuestion.length / 4) * 3).map((item) => (
                                                                <div key={kebabCase(item.question)} className="ans-item" style={{ fontSize: 55 * scaleRatio, marginBottom: 54 * scaleRatio }}>
                                                                    <div className="ques-index" style={{
                                                                        minWidth: 94.16 * scaleRatio,
                                                                        height: 94.16 * scaleRatio,
                                                                        marginRight: 43 * scaleRatio,
                                                                        fontSize: 40 * scaleRatio,
                                                                    }}>
                                                                        {item.index}
                                                                    </div>
                                                                    {item.answer}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="ans-col">
                                                        {
                                                            listQuestion.slice(Math.ceil(listQuestion.length / 4) * 3, Math.ceil(listQuestion.length / 4) * 4).map((item) => (
                                                                <div key={kebabCase(item.question)} className="ans-item" style={{ fontSize: 55 * scaleRatio, marginBottom: 54 * scaleRatio }}>
                                                                    <div className="ques-index" style={{
                                                                        minWidth: 94.16 * scaleRatio,
                                                                        height: 94.16 * scaleRatio,
                                                                        marginRight: 43 * scaleRatio,
                                                                        fontSize: 40 * scaleRatio,
                                                                    }}>
                                                                        {item.index}
                                                                    </div>
                                                                    {item.answer}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="view-page-cont" style={{ justifyContent: parseInt(current[0], 10) % 2 === 0 ? 'start' : 'end', padding: `0 ${84 * scaleRatio}px` }}>
                                                <div style={{
                                                    marginBottom: 86 * scaleRatio,
                                                    width: 111 * scaleRatio, height: 111 * scaleRatio,
                                                    fontSize: 48 * scaleRatio,
                                                }} className="view-page">{pad(current[0])}</div>
                                            </div>
                                        </div>
                                        :
                                        current[2] && current[2] === "Question"
                                            ?
                                            <div className="view-cont" style={{ width: 1800 * scaleRatio, height: 2700 * scaleRatio }}>
                                                <div style={{ textAlign: parseInt(current[0], 10) % 2 === 0 ? 'left' : 'right', padding: `${87 * scaleRatio}px ${84 * scaleRatio}px ${165 * scaleRatio}px ${84 * scaleRatio}px`, fontSize: 40 * scaleRatio }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                                <div className="view-ques-cont" style={{ paddingLeft: 187 * scaleRatio, paddingRight: 174 * scaleRatio }}>
                                                    {
                                                        current[1].map((item) => (
                                                            <Question key={item.index} item={item} scale={scaleRatio} />
                                                        ))
                                                    }

                                                </div>
                                                <div className="view-page-cont" style={{ justifyContent: parseInt(current[0], 10) % 2 === 0 ? 'start' : 'end', padding: `0 ${84 * scaleRatio}px` }}>
                                                    <div style={{
                                                        marginBottom: 86 * scaleRatio,
                                                        width: 111 * scaleRatio, height: 111 * scaleRatio,
                                                        fontSize: 48 * scaleRatio,
                                                    }} className="view-page">{pad(current[0])}</div>
                                                </div>
                                            </div>
                                            :
                                            current && current[2] === "start" ?
                                                <div className="view-cont" style={{ width: 1800 * scaleRatio, height: 2700 * scaleRatio }}>
                                                    <div style={{
                                                        textAlign: 'center', color: '#555555',
                                                        fontWeight: 500, fontSize: 48 * scaleRatio, lineHeight: '120%',
                                                        paddingTop: 78 * scaleRatio
                                                    }}>
                                                        {_.get(text, '["HISTORY OF THE WORLD"]', "")}
                                                    </div>
                                                    <div style={{ marginTop: 142 * scaleRatio, marginBottom: 64 * scaleRatio }}>
                                                        <img style={{ width: '100%' }} src={dataImage.find((x) => x.name === "start.png") ? dataImage.find((x) => x.name === "start.png").src : ""} alt="page0" />
                                                    </div>
                                                    <div className="text-title" data-text={_.get(text, `["${title}"]`, "")} style={{
                                                        "--data-padding": `0 ${84 * scaleRatio}px`,
                                                        fontWeight: 800, fontSize: calculateComponentHeight(Title, { scaleRatio, text, title }) > 600 * scaleRatio ? 230 * scaleRatio * 0.7 : 230 * scaleRatio,
                                                        lineHeight: '125%', color: "#D8984A",
                                                        textTransform: 'uppercase', WebkitTextStroke: `${7 * scaleRatio}px #000000`,
                                                        padding: `0 ${84 * scaleRatio}px`, textAlign: 'center', wordBreak: "break-word"
                                                    }}
                                                    >{_.get(text, `["${title}"]`, "")}</div>
                                                    <div style={{
                                                        textAlign: 'center', color: '#555555',
                                                        fontWeight: 500, fontSize: 48 * scaleRatio, lineHeight: '120%',
                                                        position: "absolute", bottom: 73 * scaleRatio, left: '50%', transform: 'translateX(-50%)',
                                                        textTransform: 'uppercase'
                                                    }}>
                                                        {_.get(text, '["History on Maps"]', "")}
                                                    </div>
                                                </div>
                                                :
                                                current && current[2] === "end" ?
                                                    <div className="view-cont" style={{ width: 1800 * scaleRatio, height: 2700 * scaleRatio }}>
                                                        <div style={{
                                                            textAlign: 'center', color: '#555555',
                                                            fontWeight: 500, fontSize: 48 * scaleRatio, lineHeight: '120%',
                                                            paddingTop: 78 * scaleRatio
                                                        }}>
                                                            {_.get(text, '["HISTORY OF THE WORLD"]', "")}
                                                        </div>
                                                        <div style={{ marginTop: 115 * scaleRatio, marginBottom: 0 * scaleRatio }}>
                                                            <img style={{ width: '100%' }} src={dataImage.find((x) => x.name === "end.png") ? dataImage.find((x) => x.name === "end.png").src : ""} alt="page0" />
                                                        </div>
                                                        <div className="text-end" data-text={_.get(text, '["The end"]', "")} style={{
                                                            "--data-padding": `0 ${92 * scaleRatio}px`,
                                                            fontWeight: 800, fontSize: 320 * scaleRatio,
                                                            lineHeight: '125%', color: "#fff",
                                                            textTransform: 'uppercase', WebkitTextStroke: `${8 * scaleRatio}px #000000`,
                                                            padding: `0 ${92 * scaleRatio}px`, textAlign: 'center'
                                                        }}
                                                        >{_.get(text, '["The end"]', "")}</div>
                                                        <div style={{
                                                            textAlign: 'center', color: '#555',
                                                            fontWeight: 500, fontSize: 48 * scaleRatio, lineHeight: '120%',
                                                            position: "absolute", bottom: 73 * scaleRatio, left: '50%', transform: 'translateX(-50%)',
                                                            textTransform: 'uppercase'
                                                        }}>
                                                            {_.get(text, '["History on Maps"]', "")}
                                                        </div>
                                                    </div>
                                                    :
                                                    listTitle.findIndex((x) => x === current[0]) !== -1 ?
                                                        <div className="view-cont" style={{ width: 1800 * scaleRatio, height: 2700 * scaleRatio }}>
                                                            <div style={{ textAlign: parseInt(current[0], 10) % 2 === 0 ? 'left' : 'right', padding: `${87 * scaleRatio}px ${84 * scaleRatio}px ${380 * scaleRatio}px ${84 * scaleRatio}px`, fontSize: 40 * scaleRatio }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                                            <div style={{ width: '100%', position: 'absolute', textAlign: 'center', top: '50%', transform: 'translateY(-50%)', fontWeight: 700, color: '#B98F68', fontSize: 200 * scaleRatio, lineHeight: '121%', textTransform: 'uppercase', padding: `${0 * scaleRatio}px ${120 * scaleRatio}px` }} className="view-text">
                                                                {current[1]}
                                                            </div>
                                                            <div className="view-page-cont" style={{ justifyContent: parseInt(current[0], 10) % 2 === 0 ? 'start' : 'end', padding: `0 ${84 * scaleRatio}px` }}>
                                                                <div style={{
                                                                    marginBottom: 86 * scaleRatio,
                                                                    width: 111 * scaleRatio, height: 111 * scaleRatio,
                                                                    fontSize: 48 * scaleRatio,
                                                                }} className="view-page">{pad(current[0])}</div>
                                                            </div>

                                                        </div>
                                                        :
                                                        parseInt(current[0], 10) === 1 ?
                                                            <div className="view-cont" style={{ width: 1800 * scaleRatio, height: 2700 * scaleRatio }}>
                                                                <div style={{ textAlign: parseInt(current[0], 10) % 2 === 0 ? 'left' : 'right', padding: `${87 * scaleRatio}px ${84 * scaleRatio}px ${380 * scaleRatio}px ${84 * scaleRatio}px`, fontSize: 40 * scaleRatio }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                                                <div style={{ textAlign: 'justify', fontWeight: 600, color: '#B98F68', fontSize: 55 * scaleRatio, padding: `${0 * scaleRatio}px ${120 * scaleRatio}px` }} className="view-text">
                                                                    <div dangerouslySetInnerHTML={{ __html: current[1].replace(/\n/g, "<br/>") }}></div>
                                                                </div>
                                                                <div className="view-page-cont" style={{ justifyContent: parseInt(current[0], 10) % 2 === 0 ? 'start' : 'end', padding: `0 ${84 * scaleRatio}px` }}>
                                                                    <div style={{
                                                                        marginBottom: 86 * scaleRatio,
                                                                        width: 111 * scaleRatio, height: 111 * scaleRatio,
                                                                        fontSize: 48 * scaleRatio,
                                                                    }} className="view-page">{pad(current[0])}</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            parseInt(current[0], 10) === 2 ?
                                                                <div className="view-cont" style={{ width: 1800 * scaleRatio, height: 2700 * scaleRatio }}>

                                                                    <div style={{ textAlign: parseInt(current[0], 10) % 2 === 0 ? 'left' : 'right', padding: `${87 * scaleRatio}px ${84 * scaleRatio}px ${182 * scaleRatio}px ${84 * scaleRatio}px`, fontSize: 40 * scaleRatio }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                                                    <div className="list-ml" style={{ marginTop: 50 * scaleRatio, padding: `0 ${150 * scaleRatio}px` }}>
                                                                        {
                                                                            listTitle.map((item, index) => {
                                                                                if (index % 2 === 0) {
                                                                                    return (<div key={kebabCase(data[item][1])} className="ml" style={{ marginBottom: 110 * scaleRatio }}>
                                                                                        <div className="ml-item ml-left" style={{ fontSize: 128 * scaleRatio, padding: `0 ${23 * scaleRatio}px`, paddingRight: 80 * scaleRatio }}>
                                                                                            {pad(item)}
                                                                                            <div className="ml-text" style={{ fontSize: 60 * scaleRatio, right: 240 * scaleRatio }}>{data[item][1]}</div>
                                                                                            <div className="dot-ml" style={{ width: 53.22 * scaleRatio, height: 53.22 * scaleRatio, right: -26.5 * scaleRatio }}></div>
                                                                                        </div>
                                                                                        <div className="ml-item ml-right"></div>
                                                                                    </div>)
                                                                                }
                                                                                return (<div key={kebabCase(data[item][1])} className="ml" style={{ marginBottom: 110 * scaleRatio }}>
                                                                                    <div className="ml-item ml-left"></div>
                                                                                    <div className="ml-item ml-right" style={{ fontSize: 128 * scaleRatio, padding: `0 ${23 * scaleRatio}px`, paddingLeft: 80 * scaleRatio }}>
                                                                                        {pad(item)}
                                                                                        <div className="ml-text" style={{ fontSize: 60 * scaleRatio, left: 240 * scaleRatio }}>{data[item][1]}</div>
                                                                                        <div className="dot-ml" style={{ width: 53.22 * scaleRatio, height: 53.22 * scaleRatio, left: -26.5 * scaleRatio }}></div>
                                                                                    </div>
                                                                                </div>)
                                                                            })
                                                                        }


                                                                        {/* <div className="ml" style={{ marginBottom: 110 * scaleRatio }}>
                                                                        <div className="ml-item ml-left" style={{ fontSize: 128 * scaleRatio, padding: `0 ${23 * scaleRatio}px`, paddingRight: 80 * scaleRatio }}>
                                                                            21
                                                                            <div className="ml-text" style={{ fontSize: 60 * scaleRatio, right: 240 * scaleRatio }}>The Gold Rush</div>
                                                                            <div className="dot-ml" style={{ width: 53.22 * scaleRatio, height: 53.22 * scaleRatio, right: -26.5 * scaleRatio }}></div>
                                                                        </div>
                                                                        <div className="ml-item ml-right"></div>
                                                                    </div>
                                                                    <div className="ml" style={{ marginBottom: 110 * scaleRatio }}>
                                                                        <div className="ml-item ml-left"></div>
                                                                        <div className="ml-item ml-right" style={{ fontSize: 128 * scaleRatio, padding: `0 ${23 * scaleRatio}px`, paddingLeft: 80 * scaleRatio }}>
                                                                            28
                                                                            <div className="ml-text" style={{ fontSize: 60 * scaleRatio, left: 240 * scaleRatio }}>Australia from <br />Federation to the two <br />World Wars</div>
                                                                            <div className="dot-ml" style={{ width: 53.22 * scaleRatio, height: 53.22 * scaleRatio, left: -26.5 * scaleRatio }}></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ml" style={{ marginBottom: 110 * scaleRatio }}>
                                                                        <div className="ml-item ml-left" style={{ fontSize: 128 * scaleRatio, padding: `0 ${23 * scaleRatio}px`, paddingRight: 80 * scaleRatio }}>
                                                                            41
                                                                            <div className="ml-text" style={{ fontSize: 60 * scaleRatio, right: 240 * scaleRatio }}>Post-war to Present<br />-day Australia</div>
                                                                            <div className="dot-ml" style={{ width: 53.22 * scaleRatio, height: 53.22 * scaleRatio, right: -26.5 * scaleRatio }}></div>
                                                                        </div>
                                                                        <div className="ml-item ml-right"></div>
                                                                    </div>
                                                                    <div className="ml" style={{ marginBottom: 110 * scaleRatio }}>
                                                                        <div className="ml-item ml-left"></div>
                                                                        <div className="ml-item ml-right" style={{ fontSize: 128 * scaleRatio, padding: `0 ${23 * scaleRatio}px`, paddingLeft: 80 * scaleRatio }}>
                                                                            54
                                                                            <div className="ml-text" style={{ fontSize: 60 * scaleRatio, left: 240 * scaleRatio }}>Quizzes</div>
                                                                            <div className="dot-ml" style={{ width: 53.22 * scaleRatio, height: 53.22 * scaleRatio, left: -26.5 * scaleRatio }}></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ml" style={{ marginBottom: 110 * scaleRatio }}>
                                                                        <div className="ml-item ml-left" style={{ fontSize: 128 * scaleRatio, padding: `0 ${23 * scaleRatio}px`, paddingRight: 80 * scaleRatio }}>
                                                                            70
                                                                            <div className="ml-text" style={{ fontSize: 60 * scaleRatio, right: 240 * scaleRatio }}>Answers</div>
                                                                            <div className="dot-ml" style={{ width: 53.22 * scaleRatio, height: 53.22 * scaleRatio, right: -26.5 * scaleRatio }}></div>
                                                                        </div>
                                                                        <div className="ml-item ml-right"></div>
                                                                    </div> */}
                                                                    </div>
                                                                    <div className="divider" style={{ top: 317 * scaleRatio, height: 2066.66 * scaleRatio, width: 13.3 * scaleRatio }}></div>
                                                                    <div className="view-page-cont" style={{ justifyContent: parseInt(current[0], 10) % 2 === 0 ? 'start' : 'end', padding: `0 ${84 * scaleRatio}px` }}>
                                                                        <div style={{
                                                                            marginBottom: 86 * scaleRatio,
                                                                            width: 111 * scaleRatio, height: 111 * scaleRatio,
                                                                            fontSize: 48 * scaleRatio,
                                                                        }} className="view-page">{pad(current[0])}</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="view-cont" style={{ width: 1800 * scaleRatio, height: 2700 * scaleRatio }}>
                                                                    <div style={{ textAlign: parseInt(current[0], 10) % 2 === 0 ? 'left' : 'right', padding: `${87 * scaleRatio}px ${84 * scaleRatio}px ${350 * scaleRatio}px ${84 * scaleRatio}px`, fontSize: 40 * scaleRatio }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                                                    <div className="view-img">
                                                                        <img style={{ width: 1800 * scaleRatio, height: 1016 * scaleRatio }} id="view-img" alt="" src={dataImage.find((x) => x.name === `${pad(current[0])}.jpg`) ? dataImage.find((x) => x.name === `${pad(current[0])}.jpg`).src : ""} />
                                                                    </div>
                                                                    <div style={{ fontSize: 55 * scaleRatio, padding: `${111 * scaleRatio}px ${108 * scaleRatio}px` }} className="view-text">{current[1]}</div>
                                                                    <div className="view-page-cont" style={{ justifyContent: parseInt(current[0], 10) % 2 === 0 ? 'start' : 'end', padding: `0 ${84 * scaleRatio}px` }}>
                                                                        <div style={{
                                                                            marginBottom: 86 * scaleRatio,
                                                                            width: 111 * scaleRatio, height: 111 * scaleRatio,
                                                                            fontSize: 48 * scaleRatio,
                                                                        }} className="view-page">{pad(current[0])}</div>
                                                                    </div>
                                                                </div>
                                }

                            </>
                        }
                    </div>

                </div>
            </div>

            {bigCurrent
                &&
                <>
                    {
                        bigCurrent && bigCurrent[1] === 'List Answer'
                            ?
                            <div id="view-cont" className="view-cont" style={{ width: 1800, height: 2700, marginTop: 1000 }}>
                                <div style={{ textAlign: 'right', padding: `${87}px ${84}px ${281}px ${84}px`, fontSize: 40 }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                <div className="view-ques-cont" style={{ padding: `0px ${315}px 0px ${315}px` }}>
                                    <div className="ans-row">
                                        <div className="ans-col">
                                            {
                                                listQuestion.slice(0, Math.ceil(listQuestion.length / 4)).map((item) => (
                                                    <div key={kebabCase(item.question)} className="ans-item" style={{ fontSize: 55, marginBottom: 54 }}>
                                                        <div className="ques-index" style={{
                                                            minWidth: 94.16,
                                                            height: 94.16,
                                                            marginRight: 43,
                                                            fontSize: 40,
                                                        }}>
                                                            {item.index}
                                                        </div>
                                                        {item.answer}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="ans-col">
                                            {
                                                listQuestion.slice(Math.ceil(listQuestion.length / 4), Math.ceil(listQuestion.length / 4) * 2).map((item) => (
                                                    <div key={kebabCase(item.question)} className="ans-item" style={{ fontSize: 55, marginBottom: 54 }}>
                                                        <div className="ques-index" style={{
                                                            minWidth: 94.16,
                                                            height: 94.16,
                                                            marginRight: 43,
                                                            fontSize: 40,
                                                        }}>
                                                            {item.index}
                                                        </div>
                                                        {item.answer}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="ans-col">
                                            {
                                                listQuestion.slice(Math.ceil(listQuestion.length / 4) * 2, Math.ceil(listQuestion.length / 4) * 3).map((item) => (
                                                    <div key={kebabCase(item.question)} className="ans-item" style={{ fontSize: 55, marginBottom: 54 }}>
                                                        <div className="ques-index" style={{
                                                            minWidth: 94.16,
                                                            height: 94.16,
                                                            marginRight: 43,
                                                            fontSize: 40,
                                                        }}>
                                                            {item.index}
                                                        </div>
                                                        {item.answer}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="ans-col">
                                            {
                                                listQuestion.slice(Math.ceil(listQuestion.length / 4) * 3, Math.ceil(listQuestion.length / 4) * 4).map((item) => (
                                                    <div key={kebabCase(item.question)} className="ans-item" style={{ fontSize: 55, marginBottom: 54 }}>
                                                        <div className="ques-index" style={{
                                                            minWidth: 94.16,
                                                            height: 94.16,
                                                            marginRight: 43,
                                                            fontSize: 40,
                                                        }}>
                                                            {item.index}
                                                        </div>
                                                        {item.answer}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                </div>
                                <div className="view-page-cont" style={{ justifyContent: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'start' : 'end', padding: `0 ${84}px` }}>
                                    <div style={{
                                        marginBottom: 86,
                                        width: 111, height: 111,
                                        fontSize: 48,
                                    }} className="view-page">{pad(bigCurrent[0])}</div>
                                </div>
                            </div>
                            :
                            bigCurrent[2] && bigCurrent[2] === "Question"
                                ?
                                <div id="view-cont" className="view-cont" style={{ width: 1800, height: 2700, marginTop: 1000 }}>
                                    <div style={{ textAlign: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'left' : 'right', padding: `${87}px ${84}px ${165}px ${84}px`, fontSize: 40 }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                    <div className="view-ques-cont" style={{ paddingLeft: 187, paddingRight: 174 }}>
                                        {
                                            bigCurrent[1].map((item) => (
                                                <Question key={item.index} item={item} scale={1} />
                                            ))
                                        }
                                    </div>
                                    <div className="view-page-cont" style={{ justifyContent: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'start' : 'end', padding: `0 ${84}px` }}>
                                        <div style={{
                                            marginBottom: 86,
                                            width: 111, height: 111,
                                            fontSize: 48,
                                        }} className="view-page">{pad(bigCurrent[0])}</div>
                                    </div>
                                </div>
                                :
                                bigCurrent && bigCurrent[2] === "start" ?
                                    <div id="view-cont" className="view-cont" style={{ width: 1800, height: 2700, marginTop: 1000 }}>
                                        <div style={{
                                            textAlign: 'center', color: '#555555',
                                            fontWeight: 500, fontSize: 48, lineHeight: '120%',
                                            paddingTop: 78
                                        }}>
                                            {_.get(text, '["HISTORY OF THE WORLD"]', "")}
                                        </div>
                                        <div style={{ marginTop: 142, marginBottom: 64 }}>
                                            <img style={{ width: '100%' }} src={dataImage.find((x) => x.name === "start.png") ? dataImage.find((x) => x.name === "start.png").src : ""} alt="page0" />
                                        </div>
                                        <div className="text-title" data-padding={`0 ${84}px`} data-text={_.get(text, `["${title}"]`, "")} style={{
                                            "--data-padding": `0 ${84}px`,
                                            fontWeight: 800, fontSize: calculateComponentHeight(Title, { scaleRatio: 1, text, title }) > 600 ? 230 * 0.7 : 230,
                                            lineHeight: '125%', color: "#D8984A",
                                            textTransform: 'uppercase', WebkitTextStroke: `${7}px #000000`,
                                            padding: `0 ${84}px`, textAlign: 'center', wordBreak: "break-word"
                                        }}
                                        >{_.get(text, `["${title}"]`, "")}</div>
                                        <div style={{
                                            textAlign: 'center', color: '#555555',
                                            fontWeight: 500, fontSize: 48, lineHeight: '120%',
                                            position: "absolute", bottom: 73, left: '50%', transform: 'translateX(-50%)',
                                            textTransform: 'uppercase'
                                        }}>
                                            {_.get(text, '["History on Maps"]', "")}
                                        </div>
                                    </div>
                                    :
                                    bigCurrent && bigCurrent[2] === "end" ?
                                        <div id="view-cont" className="view-cont" style={{ width: 1800, height: 2700, marginTop: 1000 }}>
                                            <div style={{
                                                textAlign: 'center', color: '#555555',
                                                fontWeight: 500, fontSize: 48, lineHeight: '120%',
                                                paddingTop: 78
                                            }}>
                                                {_.get(text, '["HISTORY OF THE WORLD"]', "")}
                                            </div>
                                            <div style={{ marginTop: 142, marginBottom: 0 }}>
                                                <img style={{ width: '100%' }} src={dataImage.find((x) => x.name === "end.png") ? dataImage.find((x) => x.name === "end.png").src : ""} alt="page0" />
                                            </div>
                                            <div className="text-end" data-text={_.get(text, '["The end"]', "")} style={{
                                                "--data-padding": `0 ${92}px`,
                                                fontWeight: 800, fontSize: 320,
                                                lineHeight: '125%', color: "#fff",
                                                textTransform: 'uppercase', WebkitTextStroke: `${8}px #000000`,
                                                padding: `0 ${92}px`, textAlign: 'center'
                                            }}
                                            >{_.get(text, '["The end"]', "")}</div>
                                            <div style={{
                                                textAlign: 'center', color: '#555555',
                                                fontWeight: 500, fontSize: 48, lineHeight: '120%',
                                                position: "absolute", bottom: 73, left: '50%', transform: 'translateX(-50%)',
                                                textTransform: 'uppercase'
                                            }}>
                                                {_.get(text, '["History on Maps"]', "")}
                                            </div>
                                        </div>
                                        :
                                        listTitle.findIndex((x) => x === bigCurrent[0]) !== -1 ?
                                            <div id="view-cont" className="view-cont" style={{ width: 1800, height: 2700, marginTop: 1000 }}>
                                                <div style={{ textAlign: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'left' : 'right', padding: `${87}px ${84}px ${380}px ${84}px`, fontSize: 40 }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                                <div style={{ width: '100%', position: 'absolute', textAlign: 'center', top: '50%', transform: 'translateY(-50%)', fontWeight: 700, color: '#B98F68', fontSize: 200, lineHeight: '121%', textTransform: 'uppercase', padding: `${0}px ${120}px` }} className="view-text">
                                                    {bigCurrent[1]}
                                                </div>
                                                <div className="view-page-cont" style={{ justifyContent: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'start' : 'end' }}>
                                                    <div style={{
                                                        marginBottom: 86, marginLeft: 84,
                                                        width: 111, height: 111,
                                                        fontSize: 48
                                                    }} className="view-page">{pad(bigCurrent[0])}</div>
                                                </div>
                                            </div>
                                            :
                                            parseInt(bigCurrent[0], 10) === 1 ?
                                                <div id="view-cont" className="view-cont" style={{ width: 1800, height: 2700, marginTop: 1000 }}>
                                                    <div style={{ textAlign: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'left' : 'right', padding: `${87}px ${84}px ${380}px ${84}px`, fontSize: 40 }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                                    <div style={{ textAlign: 'justify', fontWeight: 600, color: '#B98F68', fontSize: 55, padding: `${0}px ${120}px` }} className="view-text">
                                                        <div dangerouslySetInnerHTML={{ __html: bigCurrent[1].replace(/\n/g, "<br/>") }}></div>
                                                    </div>
                                                    <div className="view-page-cont" style={{ justifyContent: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'start' : 'end' }}>
                                                        <div style={{
                                                            marginBottom: 86, marginLeft: 84,
                                                            width: 111, height: 111,
                                                            fontSize: 48
                                                        }} className="view-page">{pad(bigCurrent[0])}</div>
                                                    </div>
                                                </div>
                                                :
                                                parseInt(bigCurrent[0], 10) === 2 ?
                                                    <div id="view-cont" className="view-cont" style={{ width: 1800, height: 2700, marginTop: 1000 }}>
                                                        <div style={{ textAlign: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'left' : 'right', padding: `${87}px ${84}px ${182}px ${84}px`, fontSize: 40 }} className="title-view">{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                                        <div className="list-ml">
                                                            {
                                                                listTitle.map((item, index) => {
                                                                    if (index % 2 === 0) {
                                                                        return (<div key={kebabCase(data[item][1])} className="ml" style={{ marginBottom: 110 }}>
                                                                            <div className="ml-item ml-left" style={{ fontSize: 128, padding: `0 ${23}px`, paddingRight: 80 }}>
                                                                                {pad(item)}
                                                                                <div className="ml-text" style={{ fontSize: 60, right: 240 }}>{data[item][1]}</div>
                                                                                <div className="dot-ml" style={{ width: 53.22, height: 53.22, right: -26.5 }}></div>
                                                                            </div>
                                                                            <div className="ml-item ml-right"></div>
                                                                        </div>)
                                                                    }
                                                                    return (<div key={kebabCase(data[item][1])} className="ml" style={{ marginBottom: 110 }}>
                                                                        <div className="ml-item ml-left"></div>
                                                                        <div className="ml-item ml-right" style={{ fontSize: 128, padding: `0 ${23}px`, paddingLeft: 80 }}>
                                                                            {pad(item)}
                                                                            <div className="ml-text" style={{ fontSize: 60, left: 240 }}>{data[item][1]}</div>
                                                                            <div className="dot-ml" style={{ width: 53.22, height: 53.22, left: -26.5 }}></div>
                                                                        </div>
                                                                    </div>)
                                                                })
                                                            }
                                                        </div>
                                                        <div className="divider"></div>
                                                        <div className="view-page-cont" style={{ justifyContent: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'start' : 'end' }}>
                                                            <div style={{
                                                                marginBottom: 86, marginLeft: 84,
                                                                width: 111, height: 111,
                                                                fontSize: 48
                                                            }} className="view-page">{pad(bigCurrent[0])}</div>
                                                        </div>                                        </div>
                                                    :
                                                    <div id="view-cont" className="view-cont" style={{ marginTop: 1000 }} >
                                                        <div className="title-view" style={{ textAlign: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'left' : 'right' }}>{_.get(text, `["History on Maps: ${title}"]`)}</div>
                                                        <div className="view-img">
                                                            <img id="view-img" alt="" src={dataImage.find((x) => x.name === `${pad(bigCurrent[0])}.jpg`) ? dataImage.find((x) => x.name === `${pad(bigCurrent[0])}.jpg`).src : ""} />
                                                        </div>
                                                        <div className="view-text" id="view-text">{bigCurrent[1]}</div>
                                                        <div className="view-page-cont" style={{ justifyContent: parseInt(bigCurrent[0], 10) % 2 === 0 ? 'start' : 'end' }}>
                                                            <div style={{
                                                                marginBottom: 86, marginLeft: 84,
                                                                width: 111, height: 111,
                                                                fontSize: 48
                                                            }} className="view-page">{pad(bigCurrent[0])}</div>
                                                        </div>                                        </div>
                    }
                </>

            }

        </div>
    );
}

function Question({ item, scale }) {
    return (
        <div key={item.index} className="view-ques" style={{ width: 1439 * scale, marginBottom: 100 * scale }}>
            <div className="ques-cont" >
                <div className="ques-title" style={{ fontSize: 55 * scale, marginBottom: 55 * scale }}>
                    <div className="ques-index" style={{
                        minWidth: 94.16 * scale,
                        height: 94.16 * scale,
                        marginRight: 43 * scale,
                        fontSize: 40 * scale,
                    }}>
                        {item.index}
                    </div>
                    {item.question}
                </div>
                {
                    item.maxLengthString < 500 ?
                        <div className="ques-op-sm">
                            <div className="ques-op-row" style={{ marginBottom: 80 * scale, marginLeft: 140 * scale }}>
                                <div className="ques-option ques-option-sm" style={{ fontSize: 50 * scale, paddingLeft: 36 * scale }}>
                                    <div className="op-circle" style={{
                                        minWidth: 77.04 * scale,
                                        height: 77.04 * scale,
                                        marginRight: 30 * scale,
                                        fontSize: 40 * scale,
                                    }}>A</div>
                                    {item.o1}
                                </div>
                                <div className="ques-option ques-option-sm" style={{ fontSize: 50 * scale, paddingLeft: 36 * scale }}>
                                    <div className="op-circle" style={{
                                        minWidth: 77.04 * scale,
                                        height: 77.04 * scale,
                                        marginRight: 30 * scale,
                                        fontSize: 40 * scale,
                                    }}>B</div>
                                    {item.o2}
                                </div>
                            </div>
                            <div className="ques-op-row" style={{ marginLeft: 140 * scale }}>
                                <div className="ques-option ques-option-sm" style={{ fontSize: 50 * scale, paddingLeft: 36 * scale }}>
                                    <div className="op-circle" style={{
                                        minWidth: 77.04 * scale,
                                        height: 77.04 * scale,
                                        marginRight: 30 * scale,
                                        fontSize: 40 * scale,
                                    }}>C</div>
                                    {item.o3}
                                </div>
                                <div className="ques-option ques-option-sm" style={{ fontSize: 50 * scale, paddingLeft: 36 * scale }}>
                                    <div className="op-circle" style={{
                                        minWidth: 77.04 * scale,
                                        height: 77.04 * scale,
                                        marginRight: 30 * scale,
                                        fontSize: 40 * scale,
                                    }}>D</div>
                                    {item.o4}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="ques-op-xl">
                            <div className="ques-op-row" style={{ marginBottom: 40 * scale, marginLeft: 140 * scale }}>
                                <div className="ques-option ques-option-xl" style={{ fontSize: 50 * scale, paddingLeft: 36 * scale }}>
                                    <div className="op-circle" style={{
                                        minWidth: 77.04 * scale,
                                        height: 77.04 * scale,
                                        marginRight: 30 * scale,
                                        fontSize: 40 * scale,
                                    }}>A</div>
                                    {item.o1}
                                </div>
                            </div>
                            <div className="ques-op-row" style={{ marginBottom: 40 * scale, marginLeft: 140 * scale }}>
                                <div className="ques-option ques-option-xl" style={{ fontSize: 50 * scale, paddingLeft: 36 * scale }}>
                                    <div className="op-circle" style={{
                                        minWidth: 77.04 * scale,
                                        height: 77.04 * scale,
                                        marginRight: 30 * scale,
                                        fontSize: 40 * scale,
                                    }}>B</div>
                                    {item.o2}
                                </div>
                            </div>
                            <div className="ques-op-row" style={{ marginBottom: 40 * scale, marginLeft: 140 * scale }}>
                                <div className="ques-option ques-option-xl" style={{ fontSize: 50 * scale, paddingLeft: 36 * scale }}>
                                    <div className="op-circle" style={{
                                        minWidth: 77.04 * scale,
                                        height: 77.04 * scale,
                                        marginRight: 30 * scale,
                                        fontSize: 40 * scale,
                                    }}>C</div>
                                    {item.o3}
                                </div>
                            </div>
                            <div className="ques-op-row" style={{ marginLeft: 140 * scale }}>
                                <div className="ques-option ques-option-xl" style={{ fontSize: 50 * scale, paddingLeft: 36 * scale }}>
                                    <div className="op-circle" style={{
                                        minWidth: 77.04 * scale,
                                        height: 77.04 * scale,
                                        marginRight: 30 * scale,
                                        fontSize: 40 * scale,
                                    }}>D</div>
                                    {item.o4}
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

function Title({ scaleRatio, text, title }) {
    return (
        <div className="text-title" data-text={_.get(text, `["${title}"]`, "")} style={{
            "--data-padding": `0 ${84 * scaleRatio}px`,
            width: 1800 * scaleRatio,
            fontWeight: 800, fontSize: 230 * scaleRatio,
            lineHeight: '125%', color: "#D8984A",
            textTransform: 'uppercase', WebkitTextStroke: `${7 * scaleRatio}px #000000`,
            padding: `0 ${84 * scaleRatio}px`, textAlign: 'center', wordBreak: "break-word"
        }}
        >{_.get(text, `["${title}"]`, "")}</div>
    )
}
export default ContentWithImage;