import logo from './logo.svg';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import ContentWithImage from './components/contentWithImage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ContentWithImage />} />

      </Routes>

      {/* <Route exact path='/coins/:slug/chart' component={({match}) => <AdvancedChart match = {match}/>}/> */}
    </div>
  );
}

export default App;
